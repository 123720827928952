.container {
  display: flex;
  flex-direction: column;
  color: #ffffff;
  text-shadow: 0px 5px 20px #6b97cb;
  font-family: Arial, Helvetica, sans-serif;
}

.main__container {
  margin: 0 auto;
  margin-top: 70px;
}

.first__header {
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 350;
  font-size: 80px;
  line-height: 90px;
  letter-spacing: 3px;
  margin-bottom: 70px;
}

.brand__name {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
}

.brand {
  text-decoration: none;
  color: #ffffff;
  border: 1px solid #96c6ff;
  border-radius: 45px;
  width: 170px;
  height: 50px;
  text-align: center;
  font-size: 20px;
  padding-top: 8px;
  text-shadow: none;
  background: rgba(109, 141, 225, 0.1);
}

.brand:hover {
  color: #b8d7fd;
}

.favorite__container {
  text-align: right;
}

.favorite {
  margin-right: 20px;
  text-decoration: none;
  color: #ffffff;
  font-size: 20px;
}

.favorite:hover {
  color: #b8d7fd;
}
