.main__container {
  text-shadow: 0px 5px 20px #6b97cb;
  font-family: Arial, Helvetica, sans-serif;
  display: flex;
  flex-direction: column;
}

.link__back {
  text-align: left;
  margin-left: 20px;
  margin-top: 20px;
  text-decoration: none;
  color: #ffffff;
  font-size: 20px;
}

.link__back:hover {
  color: #b8d7fd;
}

.second__header {
  font-weight: 350;
  font-size: 80px;
  line-height: 90px;
  letter-spacing: 3px;
  margin-bottom: 50px;
  font-family: Arial, Helvetica, sans-serif;
  color: #ffffff;
}

.container {
  margin: 0 auto;
  margin-top: 70px;
}

.model__container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
}

.model {
  text-decoration: none;
  color: #ffffff;
  border: 1px solid #96c6ff;
  border-radius: 45px;
  width: 170px;
  height: 40px;
  text-align: center;
  font-size: 20px;
  padding-top: 5px;
  text-shadow: none;
  background: rgba(109, 141, 225, 0.1);
}

.model:hover {
  color: #b8d7fd;
}
