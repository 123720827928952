@import "bootstrap/dist/css/bootstrap.min.css";

.App {
  background-image: url(./fon.jpg);
  background-size: cover;
  width: 100vw;
  height: 100vh;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
