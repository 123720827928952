.main__container {
  font-family: Arial, Helvetica, sans-serif;
  display: flex;
  flex-direction: column;
  text-shadow: 0px 5px 20px #6b97cb;
}

.link {
  text-align: left;
  margin-left: 20px;
  margin-top: 10px;
  text-decoration: none;
  color: #ffffff;
  font-size: 20px;
}

.link:hover {
  color: #b8d7fd;
}

.container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  margin-top: 40px;
  width: 850px;
}

.img__container {
  margin-right: 20px;
  margin-left: 30px;
}

.img {
  max-width: 250px;
  width: 100%;
}

.specifications {
  display: flex;
  flex-direction: column;
  color: #b8d7fd;
  text-shadow: none;
  width: 600px;
}

.btn__container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.add__btn {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  border: 1px solid #96c6ff;
  border-radius: 45px;
  width: 130px;
  height: 30px;
  text-align: center;
  font-size: 15px;
  text-shadow: none;
  background: rgba(109, 141, 225, 0.1);
}

.add__btn:hover {
  color: #b8d7fd;
}
